
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as loginKYzzYEvnxPMeta } from "/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/login.vue?macro=true";
import { default as indexJwgPhGIDYaMeta } from "/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/extras/index.vue?macro=true";
import { default as indexLYdLoggFpkMeta } from "/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/index.vue?macro=true";
import { default as participantsarS43MAIoeMeta } from "/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/participants.vue?macro=true";
import { default as index4He6NcUoD4Meta } from "/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/payment/index.vue?macro=true";
import { default as optionsxhO7foJ7x3Meta } from "/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/payment/options.vue?macro=true";
import { default as indexZ02YGAhF2SMeta } from "/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/index.vue?macro=true";
import { default as detailsau8ovJchp0Meta } from "/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/details.vue?macro=true";
import { default as favoritesEIy2hS3BOrMeta } from "/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/favorites.vue?macro=true";
import { default as indexQkdrzHN0PCMeta } from "/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/index.vue?macro=true";
export default [
  {
    name: "login___de",
    path: "/login",
    meta: loginKYzzYEvnxPMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/login.vue")
  },
  {
    name: "login___nl",
    path: "/login",
    meta: loginKYzzYEvnxPMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/login.vue")
  },
  {
    name: "login___pl",
    path: "/login",
    meta: loginKYzzYEvnxPMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/login.vue")
  },
  {
    name: "profile-bookings-bookingId-extras___de",
    path: "/profile/bookings/:bookingId()/extras",
    meta: indexJwgPhGIDYaMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/extras/index.vue")
  },
  {
    name: "profile-bookings-bookingId-extras___nl",
    path: "/profile/bookings/:bookingId()/extras",
    meta: indexJwgPhGIDYaMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/extras/index.vue")
  },
  {
    name: "profile-bookings-bookingId-extras___pl",
    path: "/profile/bookings/:bookingId()/extras",
    meta: indexJwgPhGIDYaMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/extras/index.vue")
  },
  {
    name: "profile-bookings-bookingId___de",
    path: "/profil/buchung/:bookingId()",
    meta: indexLYdLoggFpkMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/index.vue")
  },
  {
    name: "profile-bookings-bookingId___nl",
    path: "/profiel/boeking/:bookingId()",
    meta: indexLYdLoggFpkMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/index.vue")
  },
  {
    name: "profile-bookings-bookingId___pl",
    path: "/profil/rezerwacja/:bookingId()",
    meta: indexLYdLoggFpkMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/index.vue")
  },
  {
    name: "profile-bookings-bookingId-participants___de",
    path: "/profile/bookings/:bookingId()/participants",
    meta: participantsarS43MAIoeMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/participants.vue")
  },
  {
    name: "profile-bookings-bookingId-participants___nl",
    path: "/profile/bookings/:bookingId()/participants",
    meta: participantsarS43MAIoeMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/participants.vue")
  },
  {
    name: "profile-bookings-bookingId-participants___pl",
    path: "/profile/bookings/:bookingId()/participants",
    meta: participantsarS43MAIoeMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/participants.vue")
  },
  {
    name: "profile-bookings-bookingId-payment___de",
    path: "/profil/buchung/:bookingId()/zahlung",
    meta: index4He6NcUoD4Meta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/payment/index.vue")
  },
  {
    name: "profile-bookings-bookingId-payment___nl",
    path: "/profiel/boeking/:bookingId()/betaling",
    meta: index4He6NcUoD4Meta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/payment/index.vue")
  },
  {
    name: "profile-bookings-bookingId-payment___pl",
    path: "/profil/rezerwacja/:bookingId()/p%C5%82atno%C5%9B%C4%87",
    meta: index4He6NcUoD4Meta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/payment/index.vue")
  },
  {
    name: "profile-bookings-bookingId-payment-options___de",
    path: "/profil/buchung/:bookingId()/zahlung/methoden",
    meta: optionsxhO7foJ7x3Meta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/payment/options.vue")
  },
  {
    name: "profile-bookings-bookingId-payment-options___nl",
    path: "/profiel/boeking/:bookingId()/betaling/methoden",
    meta: optionsxhO7foJ7x3Meta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/payment/options.vue")
  },
  {
    name: "profile-bookings-bookingId-payment-options___pl",
    path: "/profil/rezerwacja/:bookingId()/p%C5%82atno%C5%9B%C4%87/metody",
    meta: optionsxhO7foJ7x3Meta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/[bookingId]/payment/options.vue")
  },
  {
    name: "profile-bookings___de",
    path: "/profil/buchungen",
    meta: indexZ02YGAhF2SMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/index.vue")
  },
  {
    name: "profile-bookings___nl",
    path: "/profiel/boekingen",
    meta: indexZ02YGAhF2SMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/index.vue")
  },
  {
    name: "profile-bookings___pl",
    path: "/profil/rezerwacje",
    meta: indexZ02YGAhF2SMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/bookings/index.vue")
  },
  {
    name: "profile-details___de",
    path: "/profil/details",
    meta: detailsau8ovJchp0Meta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/details.vue")
  },
  {
    name: "profile-details___nl",
    path: "/profiel/details",
    meta: detailsau8ovJchp0Meta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/details.vue")
  },
  {
    name: "profile-details___pl",
    path: "/profil/bli%C5%BCsze-dane",
    meta: detailsau8ovJchp0Meta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/details.vue")
  },
  {
    name: "profile-favorites___de",
    path: "/profil/favoriten",
    meta: favoritesEIy2hS3BOrMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/favorites.vue")
  },
  {
    name: "profile-favorites___nl",
    path: "/profiel/favorieten",
    meta: favoritesEIy2hS3BOrMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/favorites.vue")
  },
  {
    name: "profile-favorites___pl",
    path: "/profil/ulubione",
    meta: favoritesEIy2hS3BOrMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/favorites.vue")
  },
  {
    name: "profile___de",
    path: "/profil",
    meta: indexQkdrzHN0PCMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/index.vue")
  },
  {
    name: "profile___nl",
    path: "/profiel",
    meta: indexQkdrzHN0PCMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/index.vue")
  },
  {
    name: "profile___pl",
    path: "/profil",
    meta: indexQkdrzHN0PCMeta || {},
    component: () => import("/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/pages/profile/index.vue")
  }
]