import revive_payload_client_Frd8gASYWa from "/srv/www/dune-cp-lc/releases/20250318134615/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/srv/www/dune-cp-lc/releases/20250318134615/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/srv/www/dune-cp-lc/releases/20250318134615/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/srv/www/dune-cp-lc/releases/20250318134615/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JA9PnUIOcc from "/srv/www/dune-cp-lc/releases/20250318134615/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_kCUITUWXUN from "/srv/www/dune-cp-lc/releases/20250318134615/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BKbTeWPbAR from "/srv/www/dune-cp-lc/releases/20250318134615/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/srv/www/dune-cp-lc/releases/20250318134615/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/.nuxt/floating-vue.mjs";
import plugin_0pL4f4Rozx from "/srv/www/dune-cp-lc/releases/20250318134615/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_client_1hW8b5EAgS from "/srv/www/dune-cp-lc/releases/20250318134615/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_MrQwPwoIHT from "/srv/www/dune-cp-lc/releases/20250318134615/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_7CGgCC9Jj0 from "/srv/www/dune-cp-lc/releases/20250318134615/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_DqO8wZLCYH from "/srv/www/dune-cp-lc/releases/20250318134615/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_XE4XCJRsZL from "/srv/www/dune-cp-lc/releases/20250318134615/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import _1_twenty_mwb40xdv2P from "/srv/www/dune-cp-lc/releases/20250318134615/plugins/1.twenty.ts";
import _2_gtm_client_z10dvAkH3f from "/srv/www/dune-cp-lc/releases/20250318134615/plugins/2.gtm.client.ts";
import _3_auth_ZAPiGFTB7D from "/srv/www/dune-cp-lc/releases/20250318134615/plugins/3.auth.ts";
import _5_sentry_client_IeOsp1WFt3 from "/srv/www/dune-cp-lc/releases/20250318134615/plugins/5.sentry.client.ts";
import dayjs_Bqed9CCqh5 from "/srv/www/dune-cp-lc/releases/20250318134615/plugins/dayjs.ts";
import detect_ipad_client_VKlGlYDGwu from "/srv/www/dune-cp-lc/releases/20250318134615/plugins/detect-ipad.client.js";
import toast_client_6KuV3B2VXc from "/srv/www/dune-cp-lc/releases/20250318134615/plugins/toast.client.ts";
import _4_global_setup_yV2uOSylYe from "/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/plugins/4.global-setup.ts";
import cp_analytics_client_4jR4dYiDOq from "/srv/www/dune-cp-lc/releases/20250318134615/customer-portal/plugins/cp-analytics.client.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  navigation_repaint_client_JA9PnUIOcc,
  chunk_reload_client_kCUITUWXUN,
  plugin_vue3_BKbTeWPbAR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  floating_vue_EIcJ7xiw0h,
  plugin_0pL4f4Rozx,
  plugin_client_1hW8b5EAgS,
  plugin_MrQwPwoIHT,
  switch_locale_path_ssr_7CGgCC9Jj0,
  i18n_DqO8wZLCYH,
  plugin_XE4XCJRsZL,
  _1_twenty_mwb40xdv2P,
  _2_gtm_client_z10dvAkH3f,
  _3_auth_ZAPiGFTB7D,
  _5_sentry_client_IeOsp1WFt3,
  dayjs_Bqed9CCqh5,
  detect_ipad_client_VKlGlYDGwu,
  toast_client_6KuV3B2VXc,
  _4_global_setup_yV2uOSylYe,
  cp_analytics_client_4jR4dYiDOq
]