
// @ts-nocheck


export const localeCodes =  [
  "de",
  "nl",
  "pl"
]

export const localeLoaders = {
  "de": [{ key: "../../configurations/luxcamp/locales/de_DE.ts", load: () => import("../../configurations/luxcamp/locales/de_DE.ts?hash=2d541b3c&locale=de" /* webpackChunkName: "locale__srv_www_dune_cp_lc_releases_20250318134615_configurations_luxcamp_locales_de_DE_ts" */), cache: false }],
  "nl": [{ key: "../../configurations/luxcamp/locales/nl_NL.ts", load: () => import("../../configurations/luxcamp/locales/nl_NL.ts?hash=98a11d1a&locale=nl" /* webpackChunkName: "locale__srv_www_dune_cp_lc_releases_20250318134615_configurations_luxcamp_locales_nl_NL_ts" */), cache: false }],
  "pl": [{ key: "../../configurations/luxcamp/locales/pl_PL.ts", load: () => import("../../configurations/luxcamp/locales/pl_PL.ts?hash=2015d7c9&locale=pl" /* webpackChunkName: "locale__srv_www_dune_cp_lc_releases_20250318134615_configurations_luxcamp_locales_pl_PL_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "apiLocale": "de",
      "market": "ALDE",
      "code": "de",
      "language": "de-DE",
      "calenderLocale": "de",
      "flagCode": "de",
      "cookieLanguage": "de",
      "currencyFormatterIso": "de",
      "name": "Deutsch (DE)",
      "domain": "https://portal.lux-camp.de",
      "whitelabel": "https://lux-camp.de",
      "googleMapsMapId": "",
      "googleMapsApiKey": "",
      "trustpilotBusinessId": "",
      "files": [
        "/srv/www/dune-cp-lc/releases/20250318134615/configurations/luxcamp/locales/de_DE.ts"
      ]
    },
    {
      "apiLocale": "nl",
      "market": "ALNL",
      "code": "nl",
      "language": "nl-NL",
      "calenderLocale": "nl",
      "flagCode": "nl",
      "cookieLanguage": "nl",
      "currencyFormatterIso": "nl",
      "name": "Nederlands",
      "domain": "https://portal.lux-camp.nl",
      "whitelabel": "https://lux-camp.nl",
      "googleMapsMapId": "",
      "googleMapsApiKey": "",
      "files": [
        "/srv/www/dune-cp-lc/releases/20250318134615/configurations/luxcamp/locales/nl_NL.ts"
      ]
    },
    {
      "apiLocale": "pl",
      "code": "pl",
      "calenderLocale": "pl",
      "language": "pl-PL",
      "flagCode": "pl",
      "cookieLanguage": "pl",
      "currencyFormatterIso": "pl",
      "name": "Polski",
      "market": "VSPL",
      "domain": "https://portal.lux-camp.pl",
      "whitelabel": "https://lux-camp.pl",
      "googleMapsApiKey": "",
      "googleMapsMapId": "",
      "files": [
        "/srv/www/dune-cp-lc/releases/20250318134615/configurations/luxcamp/locales/pl_PL.ts"
      ]
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "./locales",
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "profile/index": {
      "de": "/profil",
      "nl": "/profiel",
      "pl": "/profil"
    },
    "profile/favorites": {
      "de": "/profil/favoriten",
      "nl": "/profiel/favorieten",
      "pl": "/profil/ulubione"
    },
    "profile/details": {
      "de": "/profil/details",
      "nl": "/profiel/details",
      "pl": "/profil/bliższe-dane"
    },
    "profile/bookings/index": {
      "de": "/profil/buchungen",
      "nl": "/profiel/boekingen",
      "pl": "/profil/rezerwacje"
    },
    "profile/bookings/[bookingId]/index": {
      "de": "/profil/buchung/[bookingId]",
      "nl": "/profiel/boeking/[bookingId]",
      "pl": "/profil/rezerwacja/[bookingId]"
    },
    "profile/bookings/[bookingId]/payment/index": {
      "de": "/profil/buchung/[bookingId]/zahlung",
      "nl": "/profiel/boeking/[bookingId]/betaling",
      "pl": "/profil/rezerwacja/[bookingId]/płatność"
    },
    "profile/bookings/[bookingId]/payment/options": {
      "de": "/profil/buchung/[bookingId]/zahlung/methoden",
      "nl": "/profiel/boeking/[bookingId]/betaling/methoden",
      "pl": "/profil/rezerwacja/[bookingId]/płatność/metody"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "apiLocale": "de",
    "market": "ALDE",
    "code": "de",
    "language": "de-DE",
    "calenderLocale": "de",
    "flagCode": "de",
    "cookieLanguage": "de",
    "currencyFormatterIso": "de",
    "name": "Deutsch (DE)",
    "domain": "https://portal.lux-camp.de",
    "whitelabel": "https://lux-camp.de",
    "googleMapsMapId": "",
    "googleMapsApiKey": "",
    "trustpilotBusinessId": "",
    "files": [
      {
        "path": "/srv/www/dune-cp-lc/releases/20250318134615/configurations/luxcamp/locales/de_DE.ts"
      }
    ]
  },
  {
    "apiLocale": "nl",
    "market": "ALNL",
    "code": "nl",
    "language": "nl-NL",
    "calenderLocale": "nl",
    "flagCode": "nl",
    "cookieLanguage": "nl",
    "currencyFormatterIso": "nl",
    "name": "Nederlands",
    "domain": "https://portal.lux-camp.nl",
    "whitelabel": "https://lux-camp.nl",
    "googleMapsMapId": "",
    "googleMapsApiKey": "",
    "files": [
      {
        "path": "/srv/www/dune-cp-lc/releases/20250318134615/configurations/luxcamp/locales/nl_NL.ts"
      }
    ]
  },
  {
    "apiLocale": "pl",
    "code": "pl",
    "calenderLocale": "pl",
    "language": "pl-PL",
    "flagCode": "pl",
    "cookieLanguage": "pl",
    "currencyFormatterIso": "pl",
    "name": "Polski",
    "market": "VSPL",
    "domain": "https://portal.lux-camp.pl",
    "whitelabel": "https://lux-camp.pl",
    "googleMapsApiKey": "",
    "googleMapsMapId": "",
    "files": [
      {
        "path": "/srv/www/dune-cp-lc/releases/20250318134615/configurations/luxcamp/locales/pl_PL.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
