
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/de'
import 'dayjs/locale/nl'
import 'dayjs/locale/pl'
import relativeTime from 'dayjs/plugin/relativeTime'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import isBetween from 'dayjs/plugin/isBetween'
import isoWeek from 'dayjs/plugin/isoWeek'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'


dayjs.extend(updateLocale)

dayjs.extend(relativeTime)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(isBetween)
dayjs.extend(isoWeek)
dayjs.extend(customParseFormat)
dayjs.extend(weekOfYear)
dayjs.extend(isSameOrAfter)
dayjs.extend(utc)



// defaultLocale: "de"


dayjs.updateLocale("de")
dayjs.locale('de')


export default dayjs
