export default defineAppConfig({
  brand: 'luxcamp',
  theme: 'charlie',
  includeLocaleInRedirects: false,
  domainConfigurations: {
    de: {
      brand: 'luxcamp',
      locale: 'de',
      currency: 'EUR',
      safetyIcons: {
        headerRight: ['DRV-rounded'],
        headerLeft: [],
        footer: {
          security: ['DRV-rounded'],
          trust: 'trustpilot2',
          payment: ['mastercard', 'visa'],
        },
      },
      socialIcons: [
        {
          link: 'https://www.facebook.com/AllcampsDE/',
          icon: 'facebook',
        },
        {
          link: 'https://www.instagram.com/allcamps/',
          icon: 'instagram',
        },
      ],
      sortingOptions: {
        sites: [
          {
            orderBy: 'popular',
            order: 'desc',
            label: 'popular-desc',
          },
          {
            orderBy: 'price',
            order: 'asc',
            label: 'price-asc',
          },
          {
            orderBy: 'price',
            order: 'desc',
            label: 'price-desc',
          },
          {
            orderBy: 'reviews',
            order: 'desc',
            label: 'review-desc',
          },
        ],
        accommodations: [
          {
            orderBy: 'popular',
            order: 'desc',
            label: 'popular-desc',
          },
          {
            orderBy: 'price',
            order: 'asc',
            label: 'price-asc',
          },
          {
            orderBy: 'price',
            order: 'desc',
            label: 'price-desc',
          },
        ],
        reviewSorting: [
          'most-helpful',
          'highest-grade',
          'lowest-grade',
          'oldest-newest',
          'newest-oldest',
        ],
        reviewGroupSorting: [
          'couple',
          'family-with-youngest-child-higher-6-years',
          'family-with-youngest-child-lower-6-years',
          'group-of-friends',
          'solo',
        ],
      },
    },
    nl: {
      brand: 'luxcamp',
      locale: 'nl',
      currency: 'EUR',
      safetyIcons: {
        headerRight: ['SGR', 'anvr', 'ncv'],
        headerLeft: [],
        footer: {
          security: ['SGR', 'anvr', 'ncv'],
          trust: 'trustpilot2',
          payment: ['mastercard', 'visa', 'ideal-rounded'],
        },
      },
      socialIcons: [
        {
          link: 'https://www.facebook.com/AllcampsNL/',
          icon: 'facebook',
        },
        {
          link: 'https://www.instagram.com/allcamps_nl/',
          icon: 'instagram',
        },
      ],
      sortingOptions: {
        sites: [
          {
            orderBy: 'popular',
            order: 'desc',
            label: 'popular-desc',
          },
          {
            orderBy: 'price',
            order: 'asc',
            label: 'price-asc',
          },
          {
            orderBy: 'price',
            order: 'desc',
            label: 'price-desc',
          },
          {
            orderBy: 'reviews',
            order: 'desc',
            label: 'review-desc',
          },
        ],
        accommodations: [
          {
            orderBy: 'popular',
            order: 'desc',
            label: 'popular-desc',
          },
          {
            orderBy: 'price',
            order: 'asc',
            label: 'price-asc',
          },
          {
            orderBy: 'price',
            order: 'desc',
            label: 'price-desc',
          },
        ],
        reviewSorting: [
          'most-helpful',
          'highest-grade',
          'lowest-grade',
          'oldest-newest',
          'newest-oldest',
        ],
        reviewGroupSorting: [
          'couple',
          'family-with-youngest-child-higher-6-years',
          'family-with-youngest-child-lower-6-years',
          'group-of-friends',
          'solo',
        ],
      },
    },
    pl: {
      brand: 'luxcamp',
      locale: 'pl',
      currency: 'PLN',
      safetyIcons: {
        headerRight: [],
        headerLeft: [],
        footer: {
          security: [],
          center: 'secure',
          payment: ['mastercard', 'visa', 'przelewy-rounded'],
        },
      },
      socialIcons: [
        {
          link: 'https://www.facebook.com/VacanceselectPolska/',
          icon: 'facebook',
        },
        {
          link: 'https://www.youtube.com/@vacanceselect',
          icon: 'youtube',
        },
        {
          link: 'https://www.instagram.com/vacanceselect_pl/',
          icon: 'instagram',
        },
      ],
      sortingOptions: {
        sites: [
          {
            orderBy: 'popular',
            order: 'desc',
            label: 'popular-desc',
          },
          {
            orderBy: 'price',
            order: 'asc',
            label: 'price-asc',
          },
          {
            orderBy: 'price',
            order: 'desc',
            label: 'price-desc',
          },
          {
            orderBy: 'reviews',
            order: 'desc',
            label: 'review-desc',
          },
        ],
        accommodations: [
          {
            orderBy: 'popular',
            order: 'desc',
            label: 'popular-desc',
          },
          {
            orderBy: 'price',
            order: 'asc',
            label: 'price-asc',
          },
          {
            orderBy: 'price',
            order: 'desc',
            label: 'price-desc',
          },
        ],
        reviewSorting: [
          'most-helpful',
          'highest-grade',
          'lowest-grade',
          'oldest-newest',
          'newest-oldest',
        ],
        reviewGroupSorting: [
          'couple',
          'family-with-youngest-child-higher-6-years',
          'family-with-youngest-child-lower-6-years',
          'group-of-friends',
          'solo',
        ],
      },
    },
  },
})
